import React, { createContext, useEffect, useState } from "react";

const defaultState = {
    darkMode: false,
    toggle: () => {}
}

const DarkModeContext = createContext(defaultState);

function DarkModeProvider(props) {
  const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("theme") === "dark") {
        setDarkMode(true);
        }
    }, []);

    useEffect(() => {
        if (darkMode) {
            document.body.classList.add("dark");
            document.body.classList.remove("light");
        } else {
            document.body.classList.add("light");
            document.body.classList.remove("dark");
        }
    }, [darkMode]);

    const toggle = () => {
        setDarkMode(!darkMode);
        localStorage.setItem("theme", !darkMode ? "dark" : "light");
    };

    return (
        <DarkModeContext.Provider value={{ darkMode, toggle }}>
        {props.children}
        </DarkModeContext.Provider>
    );
}

export { DarkModeContext, DarkModeProvider };