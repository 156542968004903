import "./src/styles/index.scss"
import "./src/styles/prism-theme.css"


import React from "react"
import { DarkModeProvider } from "./src/context/DarkModeContext"
import { Helmet } from "react-helmet"

export const wrapRootElement = ({ element }) => (
    <DarkModeProvider>
        <Helmet>
            <link rel="preload" href="/fonts/montserrat-v23-latin-regular.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/montserrat-v23-latin-500.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/montserrat-v23-latin-600.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/montserrat-v23-latin-700.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/montserrat-v23-latin-800.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
        </Helmet>
        {element}
    </DarkModeProvider>
)